<template>
  <div>
    <ul class="products-list product-list-in-box">
      <EquipmentListItem
        v-for="(item, index) in equipmentList"
        v-bind:equipment="item"
        v-bind:key="index"
        v-on:pick="pick"
      ></EquipmentListItem>
    </ul>
  </div>
</template>

<script>
import EquipmentListItem from "@/components/equipment-list-item.vue";
export default {
  name: "EquipmentListPanel",
  props: {
    equipmentList: Array
  },
  components: {
    EquipmentListItem
  },
  methods: {
    pick: function(value) {
      this.$emit("pick", value);
    }
  }
};
</script>

<style scoped></style>
