<template>
  <section>
    <EquipmentSearchPanel :source="source" />
    <div class="tab-pane tab-search" style="position: relative;">
      <div class="row">
        <div class="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1">
          <EquipmentListPanel
            v-bind:equipmentList="equipmentList"
            v-on:pick="showEquipmentWithId"
          >
          </EquipmentListPanel>
        </div>
      </div>
    </div>
    <!-- -->
  </section>
</template>

<script>
import EquipmentSearchBase from "@/views/private/EquipmentSearchBase.vue";
import EquipmentListPanel from "@/components/equipment-list-panel.vue";
export default {
  name: "EquipmentSearchList",
  extends: EquipmentSearchBase,
  components: {
    EquipmentListPanel
  },
  props: {
    filterCriteria: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fixedEquipmentProperties: [
        "nome",
        "numero_serie",
        "localizacao",
        "has_active_alarms",
        "is_connected",
        "process_area"
      ]
    };
  },
  computed: {
    allEquipmentFields() {
      let self = this;
      return (
        (self.$root.config.equipment_selection &&
          self.$root.config.equipment_selection.cols) ||
        []
      );
    },
    equipmentCustomFields() {
      let self = this;
      return this.allEquipmentFields.filter(function(i) {
        return self.fixedEquipmentProperties.indexOf(i.name) == -1;
      });
    },
    showBanner() {
      let self = this;
      return self.$root.config.equipment_selection &&
        "show_banner" in self.$root.config.equipment_selection
        ? self.$root.config.equipment_selection.show_banner
        : false;
    }
  },
  methods: {
    showField(name) {
      return (
        this.allEquipmentFields.filter(function(i) {
          return i.name == name;
        }).length > 0
      );
    }
  },
  created() {},
  mounted() {
    this.refreshEnabled = true;
  }
};
</script>
